import React from 'react';
import { Routes, Route } from 'react-router-dom';
import CarouselComponent from './components/CarouselComponent';
import AboutUsComponent from './components/AboutUsComponent';
import ContactComponent from './components/ContactComponent';
import MedicalTransportComponent from './components/MedicalTransportComponent';
import SanitaryTransportComponent from './components/SanitaryTransportComponent';
import LawContentComponent from './components/LawContentComponent';
import MedicalSecurityComponent from './components/MedicalSecurityComponent';
import NotFoundComponent from './components/NotFoundComponent';
import Layout from './layouts/Layout';

const App: React.FC = () => {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<CarouselComponent />} />
        <Route path="/o-nas" element={<AboutUsComponent />} />
        <Route path="/kontakt" element={<ContactComponent />} />
        <Route path="/transport/medyczny" element={<MedicalTransportComponent />} />
        <Route path="/transport/sanitarny" element={<SanitaryTransportComponent />} />
        <Route path="/tresc-ustawy" element={<LawContentComponent />} />
        <Route path="/zabezpieczenia-medyczne" element={<MedicalSecurityComponent />} />
        <Route path="*" element={<NotFoundComponent />} />
      </Routes>
    </Layout>
  );
};

export default App;